












import { Vue, Component, Prop } from 'vue-property-decorator'
import { HttpPost, HttpGet, Dropdown, Button, Form } from '../../../lib/mavejs'
import { url } from '@/functions'

@Component({
  components: { HttpPost, HttpGet, Dropdown, Button },
  mixins: [Form],
  methods: { url }
})
export default class AddLanguageForm extends Vue {
  @Prop() model: object;

  isSuccessFull = true
  langId = null
  options = []
  response = []

  $refs: {
    submit: Button;
  }

  handleResponse (fetch) {
    this.$refs.submit.isLoading = fetch.isFetching
    this.response = fetch.data

    this.$emit('submit', this.response)
  }
}
