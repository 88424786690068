<template>
  <div>
    <Modal ref="languageModal">
      <div class="flex flex-col">
        <div class="flex justify-between items-center">
          <div>
            <SubTitle text="Add Language"/>
          </div>
          <div @click="$refs.languageModal.isOpen = false;"
               class="p-3 -mt-6 h-12 w-12 flex items-center justify-center rounded-full cursor-pointer hover:bg-gray-light active:bg-gray">
            <span class="material-icons">close</span>
          </div>
        </div>
        <div class="mt-2">
          <span class="text-2xl font-bold text-primary-light">{{ showToAdd.name }}</span><span
          class="text-2xl ml-2 text-primary-light">{{ showToAdd.code }}</span>
        </div>
        <AddLanguageForm :model="showToAdd" @submit="fetch => handleLanguageForm(fetch)"/>
      </div>
    </Modal>
    <Card ref="card" :padding="false">
      <template v-slot:header>
        <div class="flex justify-between">
          <div class="flex items-center w-1/3">
            <h2 class="font-bold text-lg">Geo's</h2>
          </div>
          <div class="flex justify-center items-center w-1/3 -m-4">
            <PostButton label="Sync geo's" :url="url('/api/1.0/sync/geo')"
                        @response="(res) => res !== null ? $refs.geos.fetch() : ''"/>
          </div>
          <div class="w-1/3 flex justify-end items-center">
            <Searchbox v-model="filters.search.value" :dark="true" label="Search geo's"/>
          </div>
        </div>
      </template>
      <template v-slot:content>
        <HttpGet ref="geos" :url="url('/api/1.0/geo?page=' + page + '&filters=' + JSON.stringify(filters))"
                 v-model="$store.state.geos.data">
          <DataTable :store="$store.state.geos.data" :headers="headers" :sorted="sorted" v-slot="data"
                     @page="page => paginate(page)">
            <DataTableColumn>
              <div class="flex items-center w-full">
                <Tooltip :text="data.row.country === '' ? 'No country' : data.row.country">{{ data.row.name }}</Tooltip>
                <div class="bg-red rounded-full ml-2 h-2 w-2 inline-block" v-if="data.row.language === null"></div>
              </div>
            </DataTableColumn>
            <DataTableColumn>{{ data.row.code }}</DataTableColumn>
            <DataTableColumn>
              <div>
                <span v-if="data.row.language !== null" class="mr-4">{{ data.row.language.name }}</span>
                <div class="inline-block">
                  <Action variant="secondary" label="Change Language"
                          @click="() => openGeoModal(data.row.id, data.row.name, data.row.code)"/>
                </div>
              </div>
            </DataTableColumn>
          </DataTable>
        </HttpGet>
      </template>
    </Card>
  </div>
</template>
<script>
import { Component, Mixins } from 'vue-property-decorator'
import BaseLayout from '../../layouts/BaseLayout.vue'
import {
  Card,
  Notifier,
  Tooltip,
  Button,
  Searchbox,
  HttpGet,
  PostButton,
  DataTable,
  DataTableColumn,
  Action,
  Modal,
  Title,
  SubTitle,
  Dropdown,
  Functions
} from '../../lib/mavejs'
import { url } from '@/functions'
import AddLanguageForm from './form/AddLanguageForm.vue'
import { SearchPaginationResetMixin } from '@/mixins/SearchPaginationResetMixin'

@Component({
  components: {
    AddLanguageForm,
    Tooltip,
    Text,
    Searchbox,
    BaseLayout,
    Button,
    Card,
    HttpGet,
    PostButton,
    DataTable,
    DataTableColumn,
    Action,
    Modal,
    Title,
    SubTitle,
    Dropdown
  },
  mixins: [Functions],
  methods: { url }
})
export default class Geos extends Mixins(SearchPaginationResetMixin) {
  headers = [{ head: 'Name', key: 'name' }, { head: 'Code', key: 'code' }, { head: 'Languages', key: 'languages' }];
  sorted = { head: 'Name', key: 'name' };

  page = 1;

  filters = {
    search: { column: 'name', value: '' },
    order: { column: 'code', by: 'asc' }
  }

  showToAdd = {
    id: '',
    name: '',
    code: '',
    langId: 0
  }

  langId = 0;

  paginate (currentPage) {
    this.page = currentPage
  }

  openGeoModal (id, name, code) {
    this.$refs.languageModal.isOpen = true
    this.showToAdd = {
      id: id,
      name: name,
      code: code,
      langId: this.langId
    }
  }

  handleLanguageForm (fetch) {
    if (fetch !== null && fetch.data !== undefined && fetch.data.status === 200) {
      this.$refs.languageModal.isOpen = false
      this.$refs.geos.fetch()
      Notifier.send({ message: 'New language attached to geo' })
    }
  }
}
</script>
